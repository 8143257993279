import * as React from 'react'

export function ButtonContact (props) {
  return (
    <svg width={24} height={24} viewBox='0 0 29 29' fill='none' {...props}>
      <path
        d='M.01 9.945a.422.422 0 01.275-.31l9.54-3.23a.416.416 0 01.527.269.425.425 0 01-.264.536L2.12 9.907l10.184 1.798L25.62 1.953l-13.75 4.654a.416.416 0 01-.528-.268.425.425 0 01.264-.537L27.951.271l.014-.003a.41.41 0 01.356.058.428.428 0 01.143.52l-11.066 25.28a.42.42 0 01-.381.251.414.414 0 01-.288-.116l-4.617-4.447-4.094 2.625-.006.003a.411.411 0 01-.264.06.42.42 0 01-.368-.371l-.904-7.723-6.345-6.063a.428.428 0 01-.121-.4zm1.707.75l5.19 4.961 4.45-3.258-9.64-1.702zm8.525 8.15l6.038 5.815-2.264-9.534-3.774 3.719zm-.426.759l-1.22 3.462 2.89-1.854-1.67-1.608zM8 22.24l1.246-3.535.003-.007a.424.424 0 01.1-.155l6.178-6.088a.413.413 0 01.59.009.429.429 0 01-.009.6l-1.396 1.375 2.422 10.2 9.69-22.136-9.517 9.379a.413.413 0 01-.59-.01.428.428 0 01.009-.598l6.96-6.86-16.37 11.988L8 22.24z'
        fill='#000'
      />
      <path
        d='M1.34 22.196a.413.413 0 00.295-.124l3.689-3.745a.429.429 0 000-.6.413.413 0 00-.59 0l-3.69 3.745a.429.429 0 000 .6.413.413 0 00.296.124zM7.794 28.75a.413.413 0 00.295-.124l3.689-3.745a.429.429 0 000-.6.413.413 0 00-.59 0l-3.69 3.745a.429.429 0 000 .6.413.413 0 00.296.124zM1.34 28.75a.413.413 0 00.295-.124l3.689-3.745a.429.429 0 000-.6.413.413 0 00-.59 0l-3.69 3.745a.429.429 0 000 .6.413.413 0 00.296.124z'
        fill='#000'
      />
    </svg>
  )
}
