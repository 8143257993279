import * as React from 'react'
import { Icon } from './styles'

export function ContactInactive (props) {
  return (
    <Icon width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <g clipPath='url(#prefix__clip0)' fill='#8B8B8B'>
        <path d='M.007 7.025A.293.293 0 01.2 6.81l6.694-2.232a.293.293 0 01.185.556L1.488 6.998l7.146 1.243L17.98 1.5 8.33 4.717a.293.293 0 01-.185-.556L19.615.338l.01-.002a.292.292 0 01.35.4l-7.766 17.472a.293.293 0 01-.47.093L8.5 15.228l-2.872 1.814-.005.002a.292.292 0 01-.443-.215l-.634-5.338-4.453-4.19a.293.293 0 01-.085-.276zm1.198.518l3.643 3.429L7.97 8.72 1.205 7.543zm5.982 5.633l4.237 4.019-1.588-6.59-2.649 2.57zm-.298.524l-.857 2.393 2.029-1.281L6.889 13.7zm-1.275 1.823l.875-2.444.002-.004a.292.292 0 01.07-.107l4.335-4.208a.293.293 0 01.408.42l-.98.952 1.7 7.05 6.8-15.3-6.679 6.482a.293.293 0 11-.408-.42l4.884-4.741-11.487 8.285.48 4.035z' />
        <path d='M.94 15.146c.075 0 .15-.029.207-.086l2.589-2.588a.293.293 0 10-.415-.415L.733 14.646a.293.293 0 00.207.5zM5.47 19.676c.075 0 .15-.028.207-.085l2.589-2.589a.293.293 0 00-.415-.414l-2.588 2.588a.293.293 0 00.207.5zM.94 19.676c.075 0 .15-.028.207-.085l2.589-2.589a.293.293 0 00-.415-.414L.733 19.176a.293.293 0 00.207.5z' />
      </g>
    </Icon>
  )
}
