import * as React from 'react'

export function Github (props) {
  return (
    <svg width={18} height={18} viewBox='0 0 18 18' fill='none' {...props}>
      <g clipPath='url(#prefix__clip0)'>
        <path
          d='M9 .375c-4.973 0-9 3.96-9 8.844 0 3.908 2.579 7.223 6.154 8.391.45.083.615-.19.615-.425 0-.21-.008-.767-.011-1.504-2.504.533-3.032-1.186-3.032-1.186-.41-1.021-1.001-1.294-1.001-1.294-.816-.549.063-.537.063-.537.904.061 1.378.911 1.378.911.803 1.352 2.107.962 2.621.736.082-.572.313-.962.57-1.183-1.998-.221-4.099-.982-4.099-4.37 0-.965.349-1.754.926-2.373-.101-.224-.405-1.123.079-2.341 0 0 .754-.237 2.475.907a8.786 8.786 0 012.25-.299c.765.005 1.53.102 2.25.299 1.71-1.144 2.464-.907 2.464-.907.483 1.218.18 2.117.09 2.34.574.62.922 1.409.922 2.374 0 3.397-2.104 4.145-4.106 4.363.315.265.607.807.607 1.636 0 1.184-.01 2.135-.01 2.422 0 .232.157.508.618.42C15.423 16.438 18 13.12 18 9.219 18 4.335 13.97.375 9 .375z'
          fill='#000'
        />
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h18v18H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}
