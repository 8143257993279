import * as React from 'react'

export const Sun = (props) => {
  return (
    <svg height={28} viewBox='0 0 128 128' width={28} {...props}>
      <circle
        cx={64}
        cy={63.997}
        fill='#f7df1e'
        r={39.247}
        data-original='#FEDB41'
        data-old_color='#fedb41'
      />
      <g fill='#fea832'>
        <path
          d='M95.247 65.747A1.749 1.749 0 0193.5 64 29.53 29.53 0 0064 34.5a1.75 1.75 0 010-3.5 33.035 33.035 0 0133 33 1.749 1.749 0 01-1.753 1.747z'
          data-original='#FEA832'
          className='prefix__active-path'
          data-old_color='#FEA832'
          fill='#f7df1e'
        />
        <path
          d='M64 16.75a47.252 47.252 0 018.5.781c.038-.545.063-1.1.063-1.658C72.562 8.072 64 1.747 64 1.747s-8.563 6.325-8.563 14.126c0 .562.026 1.113.064 1.658A47.243 47.243 0 0164 16.75zM64 111.244a47.343 47.343 0 008.5-.78c.038.544.063 1.095.063 1.657 0 7.8-8.562 14.126-8.562 14.126s-8.563-6.324-8.563-14.126c0-.562.026-1.113.064-1.657a47.335 47.335 0 008.498.78zM97.409 30.588a47.349 47.349 0 015.457 6.562c.413-.358.82-.73 1.217-1.127 5.517-5.517 3.934-16.043 3.934-16.043s-10.526-1.58-16.043 3.934c-.4.4-.769.8-1.127 1.217a47.349 47.349 0 016.562 5.457zM30.591 97.406a47.232 47.232 0 006.562 5.457c-.358.413-.73.82-1.127 1.217-5.517 5.517-16.043 3.934-16.043 3.934S18.4 97.488 23.917 91.971c.4-.4.8-.769 1.217-1.127a47.291 47.291 0 005.457 6.562zM111.247 64a47.335 47.335 0 01-.78 8.5 23.71 23.71 0 001.657.064c7.8 0 14.126-8.563 14.126-8.563s-6.325-8.562-14.126-8.562c-.562 0-1.113.025-1.657.063a47.343 47.343 0 01.78 8.498zM16.753 64a47.335 47.335 0 00.78 8.5c-.544.038-1.1.064-1.657.064C8.075 72.56 1.75 64 1.75 64s6.325-8.562 14.126-8.562c.562 0 1.113.025 1.657.063a47.343 47.343 0 00-.78 8.499zM97.409 97.406a47.349 47.349 0 01-6.562 5.457c.358.413.73.82 1.127 1.217 5.517 5.517 16.043 3.934 16.043 3.934s1.583-10.526-3.934-16.043c-.4-.4-.8-.769-1.217-1.127a47.291 47.291 0 01-5.457 6.562zM30.591 30.588a47.349 47.349 0 00-5.457 6.562c-.413-.358-.82-.73-1.217-1.127-5.517-5.517-3.934-16.043-3.934-16.043s10.526-1.58 16.043 3.934c.4.4.769.8 1.127 1.217a47.291 47.291 0 00-6.562 5.457z'
          data-original='#000000'
          data-old_color='#000000'
          fill='#f7df1e'
        />
      </g>
    </svg>
  )
}
