import * as React from 'react'

export function ContactActive (props) {
  return (
    <svg height={20} viewBox='0 0 489.767 489.767' width={20} {...props}>
      <path
        d='M203.992 365.384l-78.692 49.7 33.134-92.579 59.316-57.571z'
        data-original='#BDD3DD'
        className='prefix__active-path'
        data-old_color='#bdd3dd'
      />
      <path
        d='M489.767.917L158.434 322.505l124.317 117.918c4.008 3.802 10.572 2.493 12.816-2.555zM489.767.917L5.473 162.348c-5.743 1.914-7.361 9.266-2.953 13.415l106.213 99.965z'
        data-original='#F9EAE0'
        data-old_color='#f9eae0'
      />
      <path
        d='M158.434 322.505L125.3 415.084l-16.567-139.356L489.767.917z'
        data-original='#ABC4D6'
        data-old_color='#abc4d6'
      />
      <path
        d='M9.334 372.884a7.477 7.477 0 005.303-2.196l66.267-66.267a7.5 7.5 0 00-10.606-10.607L4.031 360.081a7.5 7.5 0 005.303 12.803zM125.3 488.85a7.477 7.477 0 005.303-2.196l66.267-66.267a7.5 7.5 0 00-10.606-10.607l-66.267 66.267a7.5 7.5 0 005.303 12.803zM9.334 488.85a7.477 7.477 0 005.303-2.196l66.267-66.267a7.5 7.5 0 00-10.606-10.607L4.031 476.047a7.5 7.5 0 005.303 12.803z'
        data-original='#A0C8FF'
        data-old_color='#a0c8ff'
      />
      <g fill='#f9f5f3'>
        <path
          d='M.284 167.891c.65-2.46 2.441-4.626 5.19-5.543L489.767.917 208.134 204.039zM289.588 442.497l-48.321-200.389L489.767.917 295.566 437.868c-1.157 2.604-3.466 4.204-5.978 4.629z'
          data-original='#F9F5F3'
          data-old_color='#F9F5F3'
          fill='#000'
        />
      </g>
    </svg>
  )
}
