import * as React from 'react'

export function Instagram (props) {
  return (
    <svg width={18} height={18} viewBox='0 0 18 18' fill='none' {...props}>
      <path
        d='M12.375 0h-6.75A5.626 5.626 0 000 5.625v6.75A5.626 5.626 0 005.625 18h6.75A5.626 5.626 0 0018 12.375v-6.75A5.626 5.626 0 0012.375 0zm3.938 12.375a3.942 3.942 0 01-3.938 3.938h-6.75a3.942 3.942 0 01-3.938-3.938v-6.75a3.942 3.942 0 013.938-3.938h6.75a3.942 3.942 0 013.938 3.938v6.75z'
        fill='#000'
      />
      <path
        d='M9 4.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9zm0 7.313A2.816 2.816 0 016.187 9 2.816 2.816 0 019 6.187 2.816 2.816 0 0111.813 9 2.816 2.816 0 019 11.813zM13.838 4.762a.6.6 0 100-1.2.6.6 0 000 1.2z'
        fill='#000'
      />
    </svg>
  )
}
