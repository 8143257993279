import * as React from 'react'

export function Heart (props) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        d='M18.031 9.241l-.016.073c-.044.2-.1.402-.167.604H2.152a6.144 6.144 0 01-.183-.677c-.621-2.95 1.083-4.975 2.928-5.609a4.311 4.311 0 011.398-.234c.531 0 1.059.1 1.553.298.85.34 1.585.958 2.152 1.804.567-.846 1.302-1.464 2.152-1.804a4.171 4.171 0 011.553-.298c.47 0 .943.078 1.398.234 1.845.634 3.549 2.659 2.928 5.61z'
        fill='#FEC20A'
      />
      <path
        d='M18.031 9.241l-.016.073c-.044.2-.1.402-.167.604H10V5.5c.567-.846 1.302-1.464 2.152-1.804a4.171 4.171 0 011.553-.298c.47 0 .943.078 1.398.234 1.845.634 3.549 2.659 2.928 5.61z'
        fill='#FEC20A'
      />
      <path
        d='M18.015 9.314c-.044.2-.1.402-.167.604-.293.888-.795 1.775-1.498 2.645a12.674 12.674 0 01-1.038 1.134H4.688a12.662 12.662 0 01-1.038-1.134c-.703-.87-1.205-1.757-1.498-2.645a6.142 6.142 0 01-.167-.604h16.03z'
        fill='#134EA5'
      />
      <path
        d='M18.015 9.314c-.044.2-.1.402-.167.604-.293.888-.795 1.775-1.498 2.645a12.674 12.674 0 01-1.038 1.134H10V9.314h8.015z'
        fill='#134EA5'
      />
      <path
        d='M15.471 13.539a15.91 15.91 0 01-1.773 1.54 21.743 21.743 0 01-3.534 2.192l-.164.077-.164-.077a21.745 21.745 0 01-3.534-2.191 17.448 17.448 0 01-1.773-1.541H15.47z'
        fill='#E3134D'
      />
      <path
        d='M15.471 13.539a15.91 15.91 0 01-1.773 1.54 21.743 21.743 0 01-3.534 2.192l-.164.077v-3.81h5.471z'
        fill='#E3134D'
      />
    </svg>
  )
}
