import * as React from 'react'
import { Icon } from './styles'
export function BlogInactive (props) {
  return (
    <Icon width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <g clipPath='url(#prefix__clip0)' fill='#8B8B8B'>
        <path d='M19.283 3.217l-2.5-2.5a.312.312 0 00-.22-.092H.938a.313.313 0 00-.313.313v18.125c0 .172.14.312.313.312h18.125c.172 0 .312-.14.312-.313V3.438a.312.312 0 00-.092-.22zM1.25 18.75V1.25h15.183l1.875 1.875h-1.433v-.938h-.625v1.25c0 .173.14.313.313.313h2.187v15H1.25z' />
        <path d='M16.595 14.18a7.423 7.423 0 00-4.046-3.43l-.83-1.66a.314.314 0 00-.167-.152L8.679 7.833a.312.312 0 00-.333.07l-.442.443a.313.313 0 00-.07.333l1.105 2.872a.313.313 0 00.152.168l1.653.827a7.445 7.445 0 003.304 4.054l2.505 1.462a1.025 1.025 0 001.416-1.38l-1.374-2.501zM8.669 9.11l1.224 1.225.442-.442L9.11 8.669l2.103.81.73 1.457-1.008 1.007-1.458-.729-.809-2.103zm8.684 8.349a.402.402 0 01-.486.063l-2.505-1.462a6.817 6.817 0 01-3.004-3.655l1.043-1.043a6.798 6.798 0 013.646 3.12l1.374 2.5a.404.404 0 01-.068.477zM4.375 4.504l1.342 1.342c.122.122.32.122.441 0L7.5 4.504l1.342 1.342c.122.122.32.122.441 0l1.342-1.342 1.342 1.342a.311.311 0 00.441 0l1.342-1.342 1.342 1.342.441-.442-1.562-1.562a.312.312 0 00-.442 0l-1.341 1.341-1.342-1.341a.312.312 0 00-.442 0L9.063 5.183 7.72 3.842a.312.312 0 00-.442 0L5.938 5.183 4.596 3.842a.312.312 0 00-.442 0L2.592 5.404l.441.442 1.342-1.342zM5.938 8.62L4.595 7.28a.312.312 0 00-.442 0L2.592 8.841l.441.441 1.342-1.341 1.342 1.341a.312.312 0 00.441 0l1.563-1.562-.442-.442-1.341 1.342zM4.375 17.5H5v.625h-.625V17.5zM3.125 17.5h.625v.625h-.625V17.5zM1.875 17.5H2.5v.625h-.625V17.5z' />
      </g>
    </Icon>
  )
}
