// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-que-es-react-js": () => import("./../../src/pages/blog/que-es-react.js" /* webpackChunkName: "component---src-pages-blog-que-es-react-js" */),
  "component---src-pages-contacto-js": () => import("./../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-proyectos-blog-platzi-js": () => import("./../../src/pages/proyectos/blog-platzi.js" /* webpackChunkName: "component---src-pages-proyectos-blog-platzi-js" */),
  "component---src-pages-proyectos-global-js": () => import("./../../src/pages/proyectos/global.js" /* webpackChunkName: "component---src-pages-proyectos-global-js" */),
  "component---src-pages-proyectos-lovicon-js": () => import("./../../src/pages/proyectos/lovicon.js" /* webpackChunkName: "component---src-pages-proyectos-lovicon-js" */),
  "component---src-pages-proyectos-petgram-js": () => import("./../../src/pages/proyectos/petgram.js" /* webpackChunkName: "component---src-pages-proyectos-petgram-js" */),
  "component---src-pages-proyectos-platzi-swag-js": () => import("./../../src/pages/proyectos/platzi-swag.js" /* webpackChunkName: "component---src-pages-proyectos-platzi-swag-js" */),
  "component---src-pages-proyectos-platzi-video-js": () => import("./../../src/pages/proyectos/platzi-video.js" /* webpackChunkName: "component---src-pages-proyectos-platzi-video-js" */),
  "component---src-pages-proyectos-podcast-app-js": () => import("./../../src/pages/proyectos/podcast-app.js" /* webpackChunkName: "component---src-pages-proyectos-podcast-app-js" */),
  "component---src-pages-sobre-mi-js": () => import("./../../src/pages/sobre-mi.js" /* webpackChunkName: "component---src-pages-sobre-mi-js" */)
}

