import * as React from 'react'
import { Icon } from './styles'

export function AboutMyInactive (props) {
  return (
    <Icon width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <g clipPath='url(#prefix__clip0)' fill='#8B8B8B'>
        <path d='M9.857 9.634c1.324 0 2.47-.475 3.407-1.411.936-.937 1.41-2.082 1.41-3.406 0-1.323-.474-2.47-1.41-3.406C12.326.475 11.18 0 9.856 0 8.534 0 7.388.475 6.452 1.411c-.937.937-1.412 2.083-1.412 3.406 0 1.324.475 2.47 1.412 3.406.936.936 2.082 1.411 3.405 1.411zM7.28 2.24C8 1.52 8.842 1.172 9.857 1.172c1.016 0 1.859.35 2.578 1.068.718.719 1.068 1.562 1.068 2.577 0 1.016-.35 1.858-1.068 2.577-.719.719-1.562 1.068-2.578 1.068-1.015 0-1.858-.35-2.577-1.068-.718-.718-1.068-1.561-1.068-2.577 0-1.015.35-1.858 1.068-2.577zM18.286 15.379a11.9 11.9 0 00-.162-1.264 9.947 9.947 0 00-.31-1.27 6.278 6.278 0 00-.523-1.185 4.468 4.468 0 00-.788-1.027 3.471 3.471 0 00-1.131-.71 3.91 3.91 0 00-1.444-.262c-.205 0-.402.084-.783.332-.235.153-.51.33-.816.526-.262.167-.617.323-1.055.465a4.19 4.19 0 01-1.29.208c-.43 0-.864-.07-1.292-.208-.438-.142-.793-.298-1.054-.465-.304-.194-.578-.371-.817-.526-.38-.248-.578-.332-.782-.332-.52 0-1.006.088-1.444.261a3.47 3.47 0 00-1.132.711c-.297.285-.562.63-.787 1.027-.218.382-.393.78-.523 1.185-.125.39-.23.818-.31 1.27-.08.449-.135.874-.162 1.264-.027.382-.04.78-.04 1.182 0 1.044.332 1.889.986 2.512.646.615 1.501.927 2.542.927h9.63c1.04 0 1.895-.312 2.541-.927.655-.623.987-1.468.987-2.513 0-.403-.014-.8-.04-1.181zm-1.754 2.845c-.427.406-.994.604-1.733.604h-9.63c-.74 0-1.307-.198-1.734-.604-.419-.399-.622-.943-.622-1.663 0-.375.012-.745.037-1.1.024-.349.073-.732.146-1.139.072-.401.164-.778.274-1.12a5.12 5.12 0 01.425-.963c.169-.298.364-.553.578-.759.201-.192.455-.35.753-.468a2.7 2.7 0 01.923-.178c.041.022.114.064.233.14.24.158.518.337.825.533.346.22.792.42 1.325.592.544.176 1.1.265 1.651.265.551 0 1.107-.09 1.651-.265.534-.172.98-.371 1.326-.592.314-.201.584-.375.825-.532.118-.077.191-.12.232-.141.337.01.648.069.924.178.298.118.551.276.752.468.215.206.41.46.579.759a5.1 5.1 0 01.425.963c.11.342.202.719.274 1.12.073.408.122.79.146 1.139.025.354.037.724.038 1.1 0 .72-.204 1.264-.623 1.663z' />
      </g>
    </Icon>
  )
}
