import * as React from 'react'

export function BlogActive (props) {
  return (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' {...props}>
      <path
        d='M16.25 3.438V1.875h.625v.938h1.875L16.562.624H.938v18.75h18.125V3.437H16.25zM4.375 3.62l1.563 1.562L7.5 3.621l1.563 1.562 1.562-1.562 1.563 1.562 1.562-1.562 1.783 1.783-.441.442-1.342-1.342-1.563 1.563-1.562-1.563-1.563 1.563L7.5 4.504 5.937 6.067 4.375 4.504 3.033 5.846l-.441-.442 1.783-1.783zM2.5 18.437h-.625v-.625H2.5v.625zm1.25 0h-.625v-.625h.625v.625zm1.25 0h-.625v-.625H5v.625zm.938-8.933L4.375 7.942 3.033 9.283l-.441-.441 1.783-1.784 1.563 1.563 1.341-1.342.442.442-1.784 1.783zm11.636 8.176a.714.714 0 01-.864.112l-2.505-1.462a7.135 7.135 0 01-3.174-3.907l-.034-.1-1.767-.883-1.105-2.873 1.768 1.768.442-.442-1.768-1.768 2.872 1.105.884 1.768a7.135 7.135 0 013.998 3.334l1.373 2.5a.713.713 0 01-.12.848z'
        fill='#000'
      />
    </svg>
  )
}
